.input {
  display: none;
}

.label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.checkbox {

}