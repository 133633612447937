.hat {
  background: rgb(249, 249, 249);
  height: 48px;
  display: grid;
  grid-template-columns: 70px 1fr 140px 140px;
  font-size: 12px;
  color: rgb(107, 111, 118);
  align-items: center;
  gap: 20px;
  text-align: center;
}