.itemContent {
  flex-grow: 1;
  display: grid;
  grid-template-columns: 40px 1fr 140px 140px;
  gap: 20px;
}

.checkBoxItem {
  display: flex;
  justify-content: center;
}

.labelWrapper {
  display: flex;
  align-items: center;

  .label {
    font-size: 13px;

    &_withOptions {
      font-weight: bold;
      cursor: pointer;
    }
  }
}

.depthWrapper {
  display: flex;
  justify-content: center;
  cursor: default;
  user-select: none;

  .depth {
    border: 1px solid rgba(209, 209, 209, 1);
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    font-size: 15px;
  }
}
