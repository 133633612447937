* {
  box-sizing: border-box;
}
body {
  font-family: 'Inter', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  /*height: 100vh;*/
  /*overflow: hidden;*/
}

body,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}
ul,
li {
  display: block;
}
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
div,
section,
form,
input,
button,
span,
p,
img,
a {
  box-sizing: border-box;
}
a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
  color: #353535;
}

button {
  border: none;
  outline: none;
  cursor: pointer;
}

input:hover,
input:focus {
  outline: none;
}

:root {
  --color-white: #fff;
  --color-grey: rgba(223, 225, 228, 0.53);
  --color-grey-light: #eff1f4;
  --color-table-header-bg: #f9f9f9;
  --color-table-title: #6b6f76;
  --color-grey-light-active: #f0f3f9;
  --color-bg-disabled: rgba(239, 239, 239, 0.3);

  --color-black-grey: #949494;

  --color-purple: #6932b1;
  --color-purple-light: #9053ff;

  --color-blue: #6471e0;
  --color-black-light: #282a2f;
  --color-black: #000;

  --color-red: #d23333;
  --color-yelow: #ffed5c;
}

.ant-tooltip .ant-tooltip-inner {
  color: var(--color-black);
}
.ant-message {
  left: 50% !important;
  max-width: 400px !important;
  padding: 5px !important;
}
.ant-picker-dropdown {
  z-index: 9999;
}
.ant-modal-footer {
  display: none;
}
.ant-modal {
  width: min-content !important;
}
.ant-modal .ant-modal-body {
  /*height: 100vh!important;*/
}
.ant-modal-content {
  font-family: 'Inter', sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}
.ant-tooltip {
  max-width: 330px;
}

.dnd-sortable-tree_simple_tree-item {
  padding-right: 0;
  border: none;
  border-bottom: 1px solid rgba(239, 241, 244, 1);
  height: 48px;
}